import React from "react"
import { Section } from "../Section"
import { Ad } from "./Ad"
import { AdsWithColors } from "../../lib/contentful/getAllVzAds"
import { css } from "@emotion/react"
import { Col, Row } from "antd"

export const Ads = ({ ads, className }: Props) => {
  return (
    <>
      {ads.items?.length > 0 && (
        <Section
          css={css`
            padding: 40px 0 0 0;
          `}
          className={className}>
          <Row gutter={[10, 10]}>
            {ads.items.map((ad) => (
              <Col key={ad.sys.id} xs={12} sm={8} md={8} lg={8}>
                <Ad {...ad} withGradient={true} colors={ads.colors.find((c) => c.id === ad.sys.id)?.colorPalette} />
              </Col>
            ))}
          </Row>
        </Section>
      )}
    </>
  )
}

interface Props {
  ads: AdsWithColors
  className?: string
}
